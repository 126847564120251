<template>
  <v-app>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        Sign In
        <span class="ap-dark-gray--text ml-2 mr-2"> or</span>
        <router-link :to="{ name: 'sign-up' }" class="ap-blue--text">
          Sign Up
        </router-link>
      </v-card-title>
      <v-card-text>
        <v-form v-model="isValid">
          <v-text-field
            v-model="username"
            label="Username (email)"
            prepend-icon="$mdi-account-circle"
            :disabled="isLoading"
            required
            :rules="emailRules"
          />
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            prepend-icon="$mdi-lock"
            :append-icon="showPassword ? '$mdi-eye' : '$mdi-eye-off'"
            required
            :disabled="isLoading"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
        <v-alert v-if="error" type="error">
          {{ error }}
        </v-alert>
        <v-card-actions>
          <v-btn
            color="info"
            block
            :disabled="!isValid"
            :loading="isLoading"
            @click="_signIn"
          >
            Sign In
          </v-btn>
        </v-card-actions>
        <div class="d-flex mt-2">
          <v-spacer />
          <v-btn text x-small :to="{ name: 'forgot-password' }" color="indigo">
            Forgot Password?
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { get } from 'lodash-es'

export default {
  data: () => ({
    isLoading: false,
    showPassword: false,
    username: 'dev+admin_user@activeprime.com',
    password: 'AdminUser4$',
    isValid: true,
    emailRules: [
      (v) => !!v || 'Username (email) is required',
      (v) => /.+@.+/.test(v) || 'Must be a valid email',
    ],
    error: '',
  }),
  methods: {
    ...mapActions('auth', ['setJwt', 'signIn']),
    async _signIn() {
      this.isLoading = true

      try {
        await this.signIn({
          username: this.username,
          password: this.password,
        })

        this.$router.push({ name: 'home' })
      } catch (e) {
        this.error = get(
          e,
          'response.data.error.message',
          'There was a probem, please try again.'
        )
      }

      this.isLoading = false
    },
  },
}
</script>
